import React, { useEffect, useState } from 'react';

import './AppHeader.css';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/cjs/Form';
import arrow from '../../resources/arrow.png';
import ErrorModal from './ErrorModal';
import WarehouseStateService from '../warehouse/WarehouseStateService';

interface AppHeaderProps {
  position: string;
  textButton: string;
  title: string;
  to: string;
}

function isEdgeOrIE() {
  const userAgent = (navigator.userAgent || '').toLowerCase();
  return userAgent.match(/edge\/(\d+)/) !== null || userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/) !== null;
}

const AppHeader: React.FC<AppHeaderProps> = ({ position, textButton, title, to }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);

  window.onload = () => {
    WarehouseStateService.deleteAllPictures();
  };

  useEffect(() => {
    setShowErrorModal(isEdgeOrIE());
  }, []);

  const hideErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <div className="AppHeader">
      <Form.Label className="AppHeader-title">{title}</Form.Label>
      <Link to={to} className={'AppLink btn-' + position}>
        <button className={'AppHeader-button'}>
          {textButton}
          <img className={'AppHeader-image img-' + position} src={arrow} alt="Arrow" />
        </button>
      </Link>
      <ErrorModal show={showErrorModal} onHide={hideErrorModal} errorMessage="You are using IE/EDGE" />
    </div>
  );
};

export default AppHeader;
