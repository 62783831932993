import React, { useState } from 'react';
import './WorkshopBody.css';
import BlockSelect from './BlockSelect';
import BlockImage from './BlockImage';
import BlockButton from './BlockButton';

import { SizeEnum } from '../../enum/SizeEnum';

const WorkshopBody: React.FC = () => {
  const [genre, setGenre] = useState('');
  const [tech, setTech] = useState('');
  const [size, setSize] = useState(SizeEnum.S);
  const [url, setUrl] = useState('canvas.jpg');

  return (
    <div className="WorkshopBody">
      <BlockSelect genre={genre} tech={tech} setGenre={setGenre} setTech={setTech} setSize={setSize} setUrl={setUrl} />
      <div className="Image">
        <BlockImage />
      </div>
      <BlockButton url={url} genre={genre} tech={tech} size={size} />
    </div>
  );
};

export default WorkshopBody;
