import React, { useState } from 'react';
import './Select.css';
import Form from 'react-bootstrap/cjs/Form';
import genreConfig from '../../resources/select/GenreConfig.json';

interface GenreProps {
  parentCallback(name: string): void;
}

const SelectGenre = ({ parentCallback }: GenreProps) => {
  const [items] = useState(genreConfig);

  return (
    <Form.Group className="SelectItem">
      <Form.Label className="BlockSelect-label">Technique</Form.Label>
      <Form.Control
        className="Select"
        as="select"
        custom
        onChange={(event) => parentCallback(event.currentTarget.value)}
        defaultValue={''}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value} hidden={item.hidden} disabled={item.disabled}>
            {item.label}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">Select genre</Form.Control.Feedback>
    </Form.Group>
  );
};

export default SelectGenre;
