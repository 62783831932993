import React from 'react';

export interface Locked {
  locked: boolean;
  setLocked: (locked: boolean) => void;
}

const Context = React.createContext<Locked>({ locked: false, setLocked: (locked) => {} });

export default Context;
