import React, { useState } from 'react';
import './Select.css';
import Form from 'react-bootstrap/cjs/Form';
import getSizeByName, { SizeEnum } from '../../enum/SizeEnum';
import sizeConfig from '../../resources/select/SizeConfig.json';

interface SizeProps {
  parentCallback(name: SizeEnum): void;
}

const SelectSize = ({ parentCallback }: SizeProps) => {
  const [items] = useState(sizeConfig);

  return (
    <div className="SelectItem">
      <Form.Label className="BlockSelect-label">Size</Form.Label>
      <Form.Control
        className="Select"
        as="select"
        custom
        onChange={(event) => {
          parentCallback(getSizeByName(event.currentTarget.value));
        }}
      >
        {items.map((item) => (
          <option key={item.value} value={item.label}>
            {item.label}
          </option>
        ))}
      </Form.Control>
    </div>
  );
};

export default SelectSize;
