import { PictureWarehouse } from '../workshop/PictureWarehouse';
import { WarehouseStateDAO } from '../../dao/WarehouseStateDAO';
import WarehouseConfig from '../../resources/WarehouseConfig.json';
import PictureSell from '../workshop/PictureSell';

class WarehouseStateService {
  static maxSize: number = WarehouseConfig.maxSize;

  static tryToAdd(pictureWarehouse: PictureWarehouse) {
    const totalSize: number = pictureWarehouse.size.valueOf() * pictureWarehouse.count;

    if (WarehouseStateDAO.getCurrentSize() + totalSize <= WarehouseStateService.maxSize) {
      WarehouseStateDAO.addPicture(pictureWarehouse, totalSize);
      return true;
    } else {
      return false;
    }
  }

  static count() {
    let count = 0;
    WarehouseStateDAO.getAllPictures().forEach((picture) => {
      count += picture.count;
    });
    return count;
  }

  static getAllPictures() {
    return WarehouseStateDAO.getAllPictures();
  }

  static removePicture(id: string) {
    WarehouseStateDAO.remove(id);
  }

  static sellPictures(arrayPicturesSell: PictureSell[]) {
    arrayPicturesSell.forEach((item) => {
      if (parseInt(item.countSell) >= item.picture.count) {
        WarehouseStateDAO.remove(item.picture.id);
      } else {
        const newCount = item.picture.count - parseInt(item.countSell);
        WarehouseStateDAO.update(item.picture, newCount);
      }
    });
  }

  static deleteAllPictures() {
    WarehouseStateDAO.deleteAllPictures();
  }
}

export default WarehouseStateService;
