import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/cjs/Form';
import React from 'react';

interface ErrorModalProps {
  errorMessage: string;
  onHide: () => void;
  show: boolean;
}

const ErrorModal = (props: ErrorModalProps) => {
  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>{props.errorMessage}</Form.Label>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
