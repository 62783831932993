import React from 'react';

import WarehouseBody from './WarehouseBody';
import AppHeader from '../header/AppHeader';

function WarehouseApp() {
  return (
    <div>
      <AppHeader title="Warehouse" to="/" position="left" textButton="To the workshop" />
      <WarehouseBody />
    </div>
  );
}

export default WarehouseApp;
