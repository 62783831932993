import { PictureWarehouse } from '../components/workshop/PictureWarehouse';
import WarehouseState from '../components/warehouse/WarehouseState';

export class WarehouseStateDAO {
  static state: WarehouseState = {
    currentSize: 0,
    picturesWarehouse: [],
  };

  static addPicture(pictureWarehouse: PictureWarehouse, totalSize: number) {
    this.state.picturesWarehouse.push(pictureWarehouse);
    this.state.currentSize += totalSize;
  }

  static getCurrentSize() {
    return this.state.currentSize;
  }

  static getAllPictures() {
    return this.state.picturesWarehouse;
  }

  static remove(id: string) {
    const removedItem = this.state.picturesWarehouse.find((item) => item.id === id);
    if (removedItem) {
      this.state.currentSize -= removedItem.count * removedItem.size.valueOf();
      this.state.picturesWarehouse = this.state.picturesWarehouse.filter((item) => item.id !== id);
    }
  }

  static update(pictureWarehouse: PictureWarehouse, newCount: number) {
    this.state.picturesWarehouse.forEach((item) => {
      if (item.id === pictureWarehouse.id) {
        this.state.currentSize -= (item.count - newCount) * item.size.valueOf();
        item.count = newCount;
      }
    });
  }

  static deleteAllPictures() {
    this.state = {
      currentSize: 0,
      picturesWarehouse: [],
    };
  }
}
