import picturesConfig from '../../resources/pictures/pictures.json';
import ImageUrl from './ImageUrl';

const pictures = require.context('../../resources/pictures', true);

interface PictureProps {
  genre: string;
  tech: string;
}

class PictureService {
  static drawPicture(pictureProps: PictureProps) {
    const filteredPictures = picturesConfig.pictures.filter(
      (item: any) => item.genre === pictureProps.genre && pictureProps.tech === item.tech
    );

    if (filteredPictures.length === 0) {
      filteredPictures.push(picturesConfig.default);
    }

    const rand = Math.floor(Math.random() * filteredPictures.length);

    const imageUrl: ImageUrl = {
      value: pictures('./' + filteredPictures[rand].url),
      url: filteredPictures[rand].url,
    };

    return imageUrl;
  }

  static getPictureByURL(url: string) {
    const filteredPictures = picturesConfig.pictures.filter((item: any) => item.url === url);

    if (filteredPictures.length === 0) {
      filteredPictures.push(picturesConfig.default);
    }

    return pictures('./' + filteredPictures[0].url);
  }
}

export default PictureService;
