import './SendModal.css';
import React, { useCallback, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/cjs/Row';
import Col from 'react-bootstrap/cjs/Col';
import Form from 'react-bootstrap/cjs/Form';
import Button from 'react-bootstrap/cjs/Button';

interface SendModalProps {
  onHide: () => void;
  onOk: (name: string, count: number) => boolean;
  show: boolean;
}

function SendModal(props: SendModalProps) {
  const [count, setCount] = useState(0);
  const [name, setName] = useState('');

  const onSendPicture = useCallback(() => {
    if (props.onOk(name, count)) {
      setCount(0);
      setName('');
    }
  }, [name, count, setCount, setName, props]);

  return (
    <Modal show={props.show} onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Send picture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group className="justify-content-md-center" as={Row}>
            <Form.Label column sm="3">
              Количество
            </Form.Label>
            <Col sm="7">
              <Form.Control
                type="number"
                min="-10000"
                max="10000"
                onChange={(event) => setCount(parseInt(event.currentTarget.value))}
                required
              />
              <Form.Control.Feedback type="invalid">Enter correct value</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group className="justify-content-md-center" as={Row}>
            <Form.Label column sm="3">
              Имя
            </Form.Label>
            <Col sm="7">
              <Form.Control maxLength={255} onChange={(event) => setName(event.currentTarget.value)} required />
              <Form.Control.Feedback type="invalid">Enter name</Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onSendPicture}>
          Отмена
        </Button>
        <Button variant="primary" onClick={onSendPicture}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SendModal;
