export enum SizeEnum {
  S = 3,
  M = 4,
  L = 6,
}

export default function getSizeByName(name: string) {
  switch (name) {
    case 'L':
      return SizeEnum.L;
    case 'M':
      return SizeEnum.M;
    default:
      return SizeEnum.S;
  }
}
