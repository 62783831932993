import React, { useState } from 'react';

import './BlockSelect.css';
import SelectTech from './SelectTech';
import SelectGenre from './SelectGenre';
import SelectSize from './SelectSize';
import PictureService from './PictureService';
import Button from 'react-bootstrap/cjs/Button';
import { Form } from 'react-bootstrap';
import { SizeEnum } from '../../enum/SizeEnum';
import ImageUrl from './ImageUrl';

interface BlockSelectProps {
  genre: string;
  setGenre: (name: string) => void;
  setSize: (name: SizeEnum) => void;
  setTech: (name: string) => void;
  setUrl: (name: string) => void;
  tech: string;
}

function BlockSelect(props: BlockSelectProps) {
  const [validated, setValidated] = useState(false);

  const handleDraw = (event: any) => {
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    const imageUrl: ImageUrl = PictureService.drawPicture({ tech: props.tech, genre: props.genre });

    props.setUrl(imageUrl.url);

    const element = document.getElementsByClassName('BlockImage')[0];
    element.setAttribute('src', imageUrl.value);
  };

  return (
    <Form className="BlockSelect" noValidate validated={validated}>
      <SelectTech parentCallback={props.setTech} />
      <SelectGenre parentCallback={props.setGenre} />
      <SelectSize parentCallback={props.setSize} />
      <Button className="BlockSelect-button_draw" variant="dark" onClick={handleDraw}>
        DRAW
      </Button>{' '}
    </Form>
  );
}

export default BlockSelect;
