import React from 'react';

import './WarehouseItem.css';
import PictureService from '../workshop/PictureService';
import Form from 'react-bootstrap/cjs/Form';
import DeleteIcon from '../../resources/Delete.png';
import { PictureWarehouse } from '../workshop/PictureWarehouse';

interface WarehouseItemProps {
  picture: PictureWarehouse;
  checked: boolean;
  countSell: string;
  onClickDelete: (id: string) => void;
  onChangeChecked: (id: string, checked: boolean) => void;
  onChangeCount: (id: string, count: string) => void;
}

function WarehouseItem(props: WarehouseItemProps) {
  return (
    <div className="Item">
      <h4 dangerouslySetInnerHTML={{ __html: props.picture.name }} className="Title" />
      <div className="Image-container">
        <img
          className={'size' + props.picture.size}
          src={PictureService.getPictureByURL(props.picture.url)}
          alt="item.url"
        />
        <span dangerouslySetInnerHTML={{ __html: props.picture.count.toString() }} className="Image-container-text" />
        <img
          className="Image-container-button-delete"
          alt="Delete"
          src={DeleteIcon}
          onClick={() => props.onClickDelete(props.picture.id)}
        />
      </div>
      <div className="Field-container">
        <input
          type="checkbox"
          id={props.picture.id}
          className="Field-container-item Input-check"
          checked={props.checked}
          onChange={(event) => {
            props.onChangeChecked(props.picture.id, event.target.checked);
          }}
        />
        <Form.Control
          className="Field-container-item Input-field"
          value={props.countSell}
          onChange={(event) => props.onChangeCount(props.picture.id, event.target.value)}
        />
      </div>
    </div>
  );
}

export default WarehouseItem;
