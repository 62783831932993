import './Select.css';

import React, { useState } from 'react';

import Form from 'react-bootstrap/cjs/Form';
import techConfig from '../../resources/select/TechConfig.json';

interface TechProps {
  parentCallback: (name: string) => void;
}

const SelectTech = ({ parentCallback }: TechProps) => {
  const [items] = useState(techConfig);

  return (
    <Form.Group className="SelectItem">
      <Form.Label className="BlockSelect-label">Genre</Form.Label>
      <Form.Control
        className="Select"
        as="select"
        custom
        onChange={(event) => parentCallback(event.currentTarget.value)}
        required
        defaultValue={''}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value} hidden={item.hidden} disabled={item.disabled}>
            {item.label}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">Select technique</Form.Control.Feedback>
    </Form.Group>
  );
};

export default SelectTech;
