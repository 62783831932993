import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import WorkshopApp from './components/workshop/WorkshopApp';
import WarehouseApp from './components/warehouse/WarehouseApp';
import './index.css';
import Context from './Context';

const Routes: React.FC = () => {
  const [locked, setLocked] = useState(false);

  return (
    <Context.Provider value={{ locked, setLocked }}>
      <div className={'Wrapper ' + (locked && 'lock-up')}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={WorkshopApp} />
            <Route exact path="/warehouse" component={WarehouseApp} />
          </Switch>
        </BrowserRouter>
      </div>
    </Context.Provider>
  );
};

export default Routes;
