import React, { useCallback, useContext, useState } from 'react';
import './BlockButton.css';
import './SendModal.css';

import SendModal from './SendModal';
import { PictureWarehouse } from './PictureWarehouse';
import Button from 'react-bootstrap/cjs/Button';
import { Guid } from 'guid-typescript';
import WarehouseStateService from '../warehouse/WarehouseStateService';
import { SizeEnum } from '../../enum/SizeEnum';
import ErrorModal from '../header/ErrorModal';
import PictureService from './PictureService';
import Context, { Locked } from '../../Context';

interface BlockButtonProps {
  genre: string;
  size: SizeEnum;
  tech: string;
  url: string;
}

function BlockButton(props: BlockButtonProps) {
  const [errorMessage, setErrorMessage] = useState('');
  const [showSendModal, setShowSendModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const hideSendModal = useCallback(() => {
    setShowSendModal(false);
  }, [setShowSendModal]);

  const hideErrorModal = useCallback(() => {
    setShowErrorModal(false);
  }, [setShowErrorModal]);

  const sendPictureToWarehouse = useCallback(
    (name: string, count: number) => {
      if (count <= 0) {
        setErrorMessage('You entered a number equal to or less than zero.');
        setShowErrorModal(true);
        return false;
      }

      if (name === '') {
        setErrorMessage('Code: 500');
        setShowErrorModal(true);
        return false;
      }

      const pictureWarehouse: PictureWarehouse = {
        id: Guid.create().toString(),
        count: count,
        name: name,
        genre: props.genre,
        tech: props.tech,
        url: props.url,
        size: props.size,
      };

      if (!WarehouseStateService.tryToAdd(pictureWarehouse)) {
        setErrorMessage('It is not possible to add so many pictures to the warehouse.');
        setShowErrorModal(true);
        return false;
      }

      hideSendModal();

      return true;
    },
    [props.tech, props.genre, props.size, props.url, hideSendModal]
  );

  const context = useContext<Locked>(Context);

  function handleBurn() {
    const element = document.getElementsByClassName('BlockImage')[0];
    if (element.getAttribute('src') !== PictureService.getPictureByURL('canvas.jpg')) {
      element.setAttribute('src', PictureService.getPictureByURL('canvas.jpg'));
    } else {
      context.setLocked(true);
    }
  }

  return (
    <div className="BlockButton">
      <Button className="BlockButton-button" variant="dark" onClick={handleBurn}>
        Burn it down
      </Button>
      <Button
        className="BlockButton-button bug"
        variant="dark"
        onClick={() => {
          if (props.url !== 'canvas.jpg') {
            setShowSendModal(true);
          }
        }}
      >
        <div>
          <p>To the warehouse</p>
        </div>
      </Button>
      <SendModal show={showSendModal} onHide={hideSendModal} onOk={sendPictureToWarehouse} />
      <ErrorModal show={showErrorModal} onHide={hideErrorModal} errorMessage={errorMessage} />
    </div>
  );
}

export default BlockButton;
