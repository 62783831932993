import React, { useCallback, useEffect, useState } from 'react';
import './WarehouseBody.css';
import WarehouseStateService from './WarehouseStateService';
import WarehouseItem from './WarehouseItem';
import Button from 'react-bootstrap/cjs/Button';
import PictureSell from '../workshop/PictureSell';
import { PictureWarehouse } from '../workshop/PictureWarehouse';

function sleep(ms: number) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < ms);
}

function WarehouseBody() {
  const [textAlert, setTextAlert] = useState('');
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [bodyVisibility, setBodyVisibility] = useState(true);

  const initStateChecked = useCallback(() => {
    const picturesWarehouse: PictureWarehouse[] = WarehouseStateService.getAllPictures();

    return picturesWarehouse.map((item) => {
      return { picture: item, checked: false, countSell: '' };
    });
  }, []);

  const [checkedPictures, setCheckedPictures] = useState(initStateChecked());

  const onChangeChecked = useCallback(
    (id: string, checked: boolean) => {
      const newCheckedPictures = checkedPictures.map((item) => {
        if (id === item.picture.id) {
          return { picture: item.picture, checked: checked, countSell: item.countSell };
        } else {
          return item;
        }
      });

      setCheckedPictures(newCheckedPictures);
    },
    [checkedPictures]
  );

  const onChangeCount = useCallback(
    (id: string, count: string) => {
      const newCheckedPictures = checkedPictures.map((item) => {
        if (id === item.picture.id) {
          return { picture: item.picture, checked: item.checked, countSell: count };
        } else {
          return item;
        }
      });

      setCheckedPictures(newCheckedPictures);
    },
    [checkedPictures, setCheckedPictures]
  );

  const onClickDelete = useCallback(
    (id: string) => {
      WarehouseStateService.removePicture(id);

      setCheckedPictures(initStateChecked());
      setAlertVisibility(false);
    },
    [initStateChecked]
  );

  const onClickSell = useCallback(() => {
    for (let item of checkedPictures) {
      if (
        (!parseInt(item.countSell) || parseInt(item.countSell) > item.picture.count) &&
        item.checked &&
        parseInt(item.countSell) !== 0
      ) {
        setTextAlert('Wrong amuont');
        setAlertVisibility(true);
        setAlertType('warning');
        return;
      }
    }

    const pictureToSell: PictureSell[] = checkedPictures.filter((item) => {
      return item.checked;
    });

    for (let picture of pictureToSell) {
      if (picture.picture.count === parseInt(picture.countSell)) {
        sleep(5000);
        break;
      }
    }

    WarehouseStateService.sellPictures(pictureToSell);

    if (checkedPictures.length !== 0) {
      setTextAlert('The pictures sold successfully');
      setAlertVisibility(true);
      setAlertType('success');
    }

    setCheckedPictures(initStateChecked());
  }, [checkedPictures, setCheckedPictures, initStateChecked]);

  useEffect(() => {
    if (checkedPictures.length === 0) {
      setTextAlert('The warehouse is empry. Start drawing to fill it!');
      setAlertVisibility(true);
      setAlertType('info');
    }
  }, [checkedPictures]);

  window.onpopstate = () => {
    setBodyVisibility(false);
  };

  return (
    <div>
      <div className="Warehouse" hidden={!bodyVisibility}>
        <span
          className={'alert alert-' + alertType + ' alert-dismissible fade show WarehouseAlert'}
          role="alert"
          hidden={!alertVisibility}
        >
          {textAlert}
          <button
            type="button"
            className="close close-alert"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setAlertVisibility(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </span>
        <div className="Grid-container">
          {checkedPictures.map((item) => (
            <WarehouseItem
              key={item.picture.id}
              picture={item.picture}
              onClickDelete={onClickDelete}
              onChangeChecked={onChangeChecked}
              checked={item.checked}
              onChangeCount={onChangeCount}
              countSell={item.countSell}
            />
          ))}
        </div>
      </div>
      <Button className="Button-sell" variant="secondary" size="lg" onClick={onClickSell}>
        SELL
      </Button>
    </div>
  );
}

export default WarehouseBody;
