import React from 'react';

import WorkshopBody from './WorkshopBody';
import AppHeader from '../header/AppHeader';

const WorkshopApp: React.FC = () => {
  return (
    <div>
      <AppHeader title="Workshop" to="/warehouse" position="right" textButton="To the gallery" />
      <WorkshopBody />
    </div>
  );
};

export default WorkshopApp;
